import { ClockIcon, PencilSquareIcon, PrinterIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { accountTypes } from 'components/Modals/CreateUser/config'
import { SaveChanges } from 'components/SaveChanges'
import { DisbursementSchedUnitMap, DisbursementStartPurMap, noteColors, PaidByInitialMap } from 'config'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Link, Prompt } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  deleteClosingCost,
  downloadFile,
  downloadHUD1PDF,
  getAdminConfig,
  gethud1PageData,
  posthud1Notes,
  posthud1Page1Data,
} from 'services'
import Api from 'services/api'
import svgLoading from 'stories/assets/loading.svg'
import { Button, Checkbox, RadioGroup } from 'stories/components'
import { ClosingCostsDetailsModal } from 'stories/components/ClosingCostsDetailsModal/ClosingCostsDetailsModal'
import { CustomContextMenu } from 'stories/components/CustomContextMenu'
import { FieldNote } from 'stories/components/FieldNote'
import { InterimInterestModal } from 'stories/components/InterimInterestModal'
import { MIPFundingFeeModal } from 'stories/components/MIPFundingFeeModal'
import { OverrideCaclModal } from 'stories/components/OverrideCalcModal/OverrideCalcModal'
import { PrepaidItemsDetailsModal } from 'stories/components/PrepaidItemDetailsModal/PrepaidItemDetailsModal'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import {
  confirm,
  convertNegative2Parentheses,
  convertParentheses2Negative,
  formatDate,
  getDaysDiff,
  getFirstDayofNextMonth,
  getPrice1or2decimal,
  getPrice2decimal,
  openAuditLog,
} from 'utils'
import { confirmOptions } from 'utils/modals/confirmOptions'

import { HUD1InputType } from '../HUD1Page1/constant'
import { AddNewFeeModal } from './AddNewFeeModal'
import { ErrorClosingCostsModal } from './ErrorClosingCostsModal'
import { HUD1_700 } from './HUD1_700'
import { HUD1_800 } from './HUD1_800'
import { HUD1_900 } from './HUD1_900'
import { HUD1_1000 } from './HUD1_1000'
import { HUD1_1100 } from './HUD1_1100'
import { HUD1_1200 } from './HUD1_1200'
import { HUD1_1300 } from './HUD1_1300'

export const CCHeader = [
  '',
  'LINE ID',
  'HUD NO',
  'LINE NAME',
  'TRID BLOCK',
  'GFE BLOCK',
  'TYPE',
  'PAID TO',
  'RESPONSIBLE PARTY',
]

export function HUD1Page2({ pageType = 'hud1Page2' }: { pageType?: string }) {
  const [action, setAction] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [inputs, setInputs] = useState<any>({})
  const [data, setData] = useState<any>({})
  const [orgData, setOrgData] = useState<any>({})
  const [notes, setNotes] = useState<any>({})
  const [IDs, setIDs] = useState<Array<string>>([])
  const [ClosingCosts, setClosingCosts] = useState<Array<Record<string, string>>>([])
  const [PrepaidItems, setPrepaidItems] = useState<Array<Record<string, string>>>([])
  const [Parties, setParties] = useState<Array<Record<string, string>>>([])
  const [changed, setChanged] = useState(false)
  const [ovData, setOVData] = useState<any>({})
  const [closingCostsDetailData, setClosingCostsDetailData] = useState<any>({})
  const [prepaidItemsDetailData, setPrepaidItemsDetailData] = useState<any>({})
  const [isAddCCModalOpen, setIsAddCCModalOpen] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [errorClosingCosts, setErrorClosingCosts] = useState<Array<Record<string, any>>>([])
  const [isNewFeeLoading, setIsNewFeeLoading] = useState<boolean>(false)
  const [ignoreEror, setIgnoreError] = useState<boolean>(false)
  const [isUpdateMonthReserve, setIsUpdateMonthReserve] = useState<boolean>(false)
  const [showMIPFundingModal, setShowMIPFundingModal] = useState(false)
  const [showInterimInterestModal, setShowInterimInterestModal] = useState(false)
  const [focusedKey, setFocusedKey] = useState('')
  const [ruleConfig, setRuleConfig] = useState<Array<any>>([])
  const [editField, setEditField] = useState({ key: '', value: null })
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    key: '',
    value: null,
  })

  const containerRef = useRef<HTMLDivElement | null>(null)

  const loanNumber = Api.getLoanNumber()
  const calculateKeys = [
    'Loan._HUD1OurOriginationCharge',
    'Loan._HUD1CreditOrChargeForIntRate',
    'Loan._HUD1AdjustedOriginationCharge',
    'Loan._DailyInterest',
    'Loan._InterimInterest',
    'Loan.interimInterestBorrower',
    'Loan._GFEBlockTitleServices',
    'POC.B.Total',
    'POC.S.Total',
    'Loan._GFEBlockRecordingCharges',
    'Loan._GFEBlockTransferTaxes',
    'Loan._GFEBlockServicesYouCanShopFor',
    'HUD1.TotalBorrowerCharges',
    'HUD1.TotalSellerCharges',
    'Loan._GFEBlockEscrowDeposit',
    'Recording Fee Deed',
    'Recording Fee Mortgage',
    'Recording Fee Release',
    'City/Country Deed',
    'City/Country Mortgage',
    'State Deed',
    'State Mortgage',
    'HUD1.disbursementTotal',
    'HUD1.disbursementToBorrower',
    'Loan.AggregateAdjustment',
  ]

  const paymentKeys = [
    'PrepaidItems.0.Payment',
    'PrepaidItems.2.Payment',
    'PrepaidItems.3.Payment',
    'PrepaidItems.4.Payment',
    'PrepaidItems.5.Payment',
    'PrepaidItems.6.Payment',
  ]

  const isHud1a = useMemo(() => pageType == 'hud1A', [pageType])

  const initData = async () => {
    setAction('')
    setIsLoading(true)
    const [res, ruleConfig] = await Promise.all([gethud1PageData('page2'), getAdminConfig('rules')])
    setRuleConfig(ruleConfig.revalidateLoan)
    if (res.success) {
      setInputs(res.inputs)
      setData(res.data)
      setOrgData(res.data)
      setIDs(res.IDs)
      setClosingCosts(res.ClosingCosts)
      setPrepaidItems(res.PrepaidItems)
      setParties(res.Parties)
      setErrorClosingCosts(res.errorClosingCosts)
      setClosingCostsDetailData({})
      setNotes(res.notes)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    initData()
  }, [])

  useEffect(() => {
    checkMonthReserve()
  }, [data])

  const onChange = (key: string, value: any) => {
    const temp = cloneDeep(data)
    temp[key] = value
    setData(temp)
    if (!changed) setChanged(true)
  }

  const onBlur = (key: string, value: any, type?: string, orgData?: Record<string, any>) => {
    setFocusedKey('')
    const hasOrgData = !!orgData
    if (!orgData) orgData = data
    let temp = cloneDeep(orgData!)
    const keys = key.split('.')

    if (type === 'number') {
      value = convertParentheses2Negative(value)
      temp[key] = getPrice1or2decimal(value.replaceAll(',', '')).replaceAll(',', '')
    } else temp[key] = value

    if (key === 'Status.SchedFundingDate') {
      temp['HUD1.InterimInterestDateFrom'] = value
      temp['HUD1.InterimInterestDateTo'] = getFirstDayofNextMonth(value)
    }
    if (keys[0] === 'ClosingCosts') {
      const pointKey = keys[0] + '.' + keys[1] + '.' + 'Points'
      const borrowerKey = keys[0] + '.' + keys[1] + '.' + 'BorrowerAmount'
      const sellerKey = keys[0] + '.' + keys[1] + '.' + 'SellerAmount'
      if (!temp[pointKey]) {
        if (orgData![pointKey]) {
          temp[borrowerKey] = ''
          temp[sellerKey] = ''
        }
      } else if (temp[pointKey] <= 0) {
        temp[borrowerKey] = 0
      } else {
        temp[borrowerKey] = (temp[pointKey] * temp['HUD1.Line202Calc']) / 100 - temp[sellerKey]
      }
    }
    if (!hasOrgData) setData(temp)
    else return temp
  }

  const onFocus = (key: string) => {
    const temp = cloneDeep(data)
    temp[key] = convertNegative2Parentheses(data[key])
    setData(temp)
    setFocusedKey(key)
  }

  const handleContextMenu = (event: any, key: string, value: any) => {
    event.preventDefault()
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect()
      setContextMenu({
        visible: true,
        x: event.clientX - containerRect.left,
        y: event.clientY - containerRect.top,
        key: key,
        value: value,
      })
    }
  }

  useEffect(() => {
    if (contextMenu.visible) {
      document.addEventListener('click', closeContextMenu)
    } else {
      document.removeEventListener('click', closeContextMenu)
    }

    // Cleanup when component unmounts or when context menu is closed
    return () => {
      document.removeEventListener('click', closeContextMenu)
    }
  }, [contextMenu.visible])

  const closeContextMenu = () => {
    setContextMenu({
      visible: false,
      x: 0,
      y: 0,
      key: '',
      value: null,
    })
  }

  const onFieldNoteModalSubmit = async (valueKey: string, data: Record<string, any>) => {
    setEditField({ key: '', value: null })
    setIsLoading(true)
    const res = await posthud1Notes(valueKey, data)
    if (res.success) {
      const temp = cloneDeep(notes)
      temp[valueKey] = data
      setNotes(temp)
    }
    setIsLoading(false)
  }

  const onCopyClick = (valueKey: string) => {
    let temp = cloneDeep(data)
    if (valueKey == 'HUD1.InterimInterestDateFrom') temp[valueKey] = data['Status.SchedFundingDate'].split('T')[0]
    else if (valueKey == 'HUD1.InterimInterestDateTo') temp[valueKey] = data['Loan.FirstPaymentDate'].split('T')[0]
    else if (valueKey == 'Loan.InterimInterestDays')
      temp[valueKey] = getDaysDiff(data['HUD1.InterimInterestDateTo'], data['HUD1.InterimInterestDateFrom']) + 1
    setData(temp)
    if (!changed) setChanged(true)
  }

  const closeOVModal = (save: false, data: any) => {
    if (save) onChange(ovData.overrideKey, data.ovValue)
    setOVData({})
  }

  const closeAddNewFeeModal = (save: false) => {
    setIsAddCCModalOpen(false)
    setIsNewFeeLoading(false)
    if (save) {
      if (changed) onSaveChanges()
      initData()
      toast('Successfully added new fee(s)', { type: 'success' })
    }
  }

  const onDeleteFee = () => {
    initData()
    toast('Successfully deleted fee', { type: 'success' })
  }

  const onErrorModalClose = (cc: any) => {
    setClosingCostsDetailData({
      hudNo: { value: data[cc.lineNoKey], key: cc.lineNoKey },
      lineName: { value: cc.title, key: 'title' },
      isPoints: {
        value:
          (data[cc.lineNoKey] == 801 && [32, 34].includes(data[cc.ClosingCostTypeKey])) || data[cc.lineNoKey] == 802,
        key: 'isPoints',
      },
      points: { value: data[cc.feeMultipleKey], key: cc.feeMultipleKey },
      borrowerAmount: { value: data[cc.feeBKey], key: cc.feeBKey },
      sellerAmount: { value: data[cc.feeSKey], key: cc.feeSKey },
      TRIDBlock: { value: data[cc.TRIDBlockKey], key: cc.TRIDBlockKey },
      GFEBlock: { value: data[cc.GFEBlockKey], key: cc.GFEBlockKey },
      ClosingCostType: { value: data[cc.ClosingCostTypeKey], key: cc.ClosingCostTypeKey },
      PaidToType: { value: data[cc.PaidToTypeKey], key: cc.PaidToTypeKey },
      Financed: { value: data[cc.FinancedKey], key: cc.FinancedKey },
      ResponsiblePartyType: { value: data[cc.ResponsiblePartyTypeKey], key: cc.ResponsiblePartyTypeKey },
      NetFromWire: { value: data[cc.NetFromWireKey], key: cc.NetFromWireKey },
      NotCounted: { value: data[cc.NotCountedKey], key: cc.NotCountedKey },
      ProviderChosenByBorrower: {
        value: data[cc.ProviderChosenByBorrowerKey],
        key: cc.ProviderChosenByBorrowerKey,
      },
      PaidByOtherType: { value: data[cc.PaidByOtherTypeKey], key: cc.PaidByOtherTypeKey },
      poc: { value: data[cc.pocKey], key: cc.pocKey },
      PPFC: { value: data[cc.PPFCKey], key: cc.PPFCKey },
      lineId: { value: data[cc.lineIdKey], key: cc.lineIdKey },
      PointsAndFeesAmountOV: { value: data[cc.PointsAndFeesAmountOVKey], key: cc.PointsAndFeesAmountOVKey },
      PaidTo: { value: data[cc.PaidToNameKey], key: cc.PaidToNameKey },
      isPOCDisabled: { value: cc?.isPOCDisabled, key: '' },
      partyType: { value: cc?.partyType, key: '' },
      partyName: { value: data[cc.defaultValueKey], key: '' },
      ccid: { value: cc.ccid, key: '' },
    })
  }

  const closeCostingDetailModal = (save: false, detailData: any) => {
    if (save) {
      let newData = cloneDeep(data)
      Object.keys(detailData).map((v) => {
        const { key, value } = detailData[v]
        if (data[key] !== value && Object.keys(data).includes(key)) {
          newData = onBlur(key, value, '', newData)
        }
      })
      setData(newData)
      setChanged(true)
    }
    setClosingCostsDetailData({})
    setPrepaidItemsDetailData({})
  }

  const onSaveChanges = async () => {
    setChanged(false)
    const ccTemp = cloneDeep(ClosingCosts)
    const ppTemp = cloneDeep(PrepaidItems)
    let json: any = {}
    Object.keys(data).map((key) => {
      if (JSON.stringify(data[key]) != JSON.stringify(orgData[key])) {
        json[key] = data[key]
        const params = key.split('.')
        if (params[0] === 'ClosingCosts') ccTemp[+params[1]][params[2]] = data[key]
        if (params[0] === 'PrepaidItems') ppTemp[+params[1]][params[2]] = data[key]
      }
    })
    const paymentChanged = !!Object.keys(json).filter((v) => paymentKeys.includes(v)).length
    if (paymentChanged) {
      const result = await confirm(
        `Due to a change, a revalidation is required.  A task will be generated for the ${ruleConfig
          .map((v) => accountTypes[v])
          .join(', ')} to clear this item.`,
      )
      if (!result) return
    }
    calculateKeys.map((v) => {
      json[v] = calculateValue(v)
    })

    const initializedKeys = [inputs['802'][2]?.feeMultipleKey, inputs['802'][2]?.feeBKey]

    initializedKeys.map((v) => {
      json[v] = data[v]
    })
    if (Object.keys(json).length > 0) {
      json.IDs = IDs
      json.ClosingCosts = ClosingCosts
      json.PrepaidItems = PrepaidItems
      json.Parties = Parties
      json.OriginData = orgData
      setAction('saveChanges')
      await posthud1Page1Data('page1', json)
      setAction('')
      setOrgData(data)
      setClosingCosts(ccTemp)
      setPrepaidItems(ppTemp)
    }
    initData()
  }

  const calculateValue = (key: string) => {
    let CALCULATED_VALUE: any = undefined
    const keys: Array<string> = []
    switch (key) {
      case 'Loan._HUD1OurOriginationCharge':
        inputs['originationCharge']?.map((input: any) => {
          if (input.error && !ignoreEror) return
          if (input.feeBKey) keys.push(input.feeBKey)
          if (input.feeSKey) keys.push(input.feeSKey)
        })
        CALCULATED_VALUE = dataSum(keys)
        break
      case 'Loan._HUD1CreditOrChargeForIntRate':
        const input = inputs['802'][2]
        if (input && (!input.error || ignoreEror)) CALCULATED_VALUE = +data[input.feeBKey] + +data[input.feeSKey]
        else CALCULATED_VALUE = 0
        break
      case 'Loan._HUD1AdjustedOriginationCharge':
        CALCULATED_VALUE =
          +calculateValue('Loan._HUD1OurOriginationCharge') + +calculateValue('Loan._HUD1CreditOrChargeForIntRate')
        break
      case 'Loan._DailyInterest':
        const roundTo = data['Loan.InterimIntDecimalsOV'] == 0 ? 2 : Number(data['Loan.InterimIntDecimalsOV']) + 1
        CALCULATED_VALUE =
          Math.round(
            ((+data['HUD1.Line202Calc'] *
              (data['Loan.BDInterimIntCalcMethodOV'] == 3 && data['Loan.LoanProductType'] == 1
                ? +data['Loan.ARMIndexValue'] + +data['Loan.ARMMargin']
                : +data['Loan.interestRate'])) /
              100 /
              (data['Loan.InterimIntDaysPerYearOV'] == 2 ? 365 : 360)) *
              Math.pow(10, roundTo),
          ) / Math.pow(10, roundTo)
        break
      case 'Loan._InterimInterest':
        if (data['Loan.DailyInterestOV'])
          CALCULATED_VALUE = +data['Loan.InterimInterestDays'] * +data['Loan.DailyInterestOV']
        else {
          CALCULATED_VALUE = +data['Loan.InterimInterestDays'] * +calculateValue('Loan._DailyInterest')
        }
        CALCULATED_VALUE = Math.round(CALCULATED_VALUE * 100) / 100
        break
      case 'Loan.interimInterestBorrower':
        CALCULATED_VALUE =
          +(data['Loan.InterimInterestOV'] || calculateValue('Loan._InterimInterest') || 0) -
          +data['Loan.InterimInterestPBSDesired']
        break
      case 'Loan._GFEBlockTitleServices':
        inputs['titleInsuranceChilds']?.map((input: any) => {
          if (input.error && !ignoreEror) return
          if (!data[input.pocKey]) {
            keys.push(input.feeBKey)
            keys.push(input.feeSKey)
          }
        })
        CALCULATED_VALUE = dataSum(keys)
        break
      case 'POC.B.Total':
        inputs['titleInsuranceChilds']?.map((input: any) => {
          if (input.error && !ignoreEror) return
          if (data[input.pocKey] && input.key == 1101) {
            keys.push(input.feeBKey)
          }
        })
        CALCULATED_VALUE = dataSum(keys)
        break
      case 'POC.S.Total':
        inputs['titleInsuranceChilds']?.map((input: any) => {
          if (input.error && !ignoreEror) return
          if (data[input.pocKey] && input.key == 1101 && data[input.PaidByOtherTypeKey] == 0) {
            keys.push(input.feeSKey)
          }
        })
        CALCULATED_VALUE = dataSum(keys)
        break
      case 'Loan._GFEBlockRecordingCharges':
        inputs['govermentChargesChilds']?.map((input: any) => {
          if (input.error && !ignoreEror) return
          if (input.feeBKey) keys.push(input.feeBKey)
          if (input.feeSKey) keys.push(input.feeSKey)
        })
        CALCULATED_VALUE = dataSum(keys)
        break
      case 'Loan._GFEBlockTransferTaxes':
        inputs['transferTaxChilds']?.map((input: any) => {
          if (input.error && !ignoreEror) return
          if (input.feeBKey) keys.push(input.feeBKey)
          if (input.feeSKey) keys.push(input.feeSKey)
        })
        CALCULATED_VALUE = dataSum(keys)
        break
      case 'Loan._GFEBlockServicesYouCanShopFor':
        inputs['1302-1400']?.map((input: any) => {
          if (input.error && !ignoreEror) return
          if ([3, 5, 99].includes(Number(data[input['GFEBlockKey']]))) return
          if (!data[input.pocKey]) {
            keys.push(input.feeBKey)
            keys.push(input.feeSKey)
          }
        })
        CALCULATED_VALUE = dataSum(keys)
        break
      case 'HUD1.TotalBorrowerCharges':
        inputs['additionalFees']?.map((input: any) => {
          if (input.error && !ignoreEror) return
          if (!data[input.pocKey]) {
            keys.push(input.feeBKey)
            keys.push(input.feeSKey)
          }
        })
        ;[...inputs['1102-1104'], ...inputs['1109-1200'], ...inputs['1206-1300'], ...inputs['1302-1400']].map(
          (input: any) => {
            if ((input.error && !ignoreEror) || data[input.pocKey]) return
            if (data[input.GFEBlockKey] == 3 || data[input.GFEBlockKey] == 5) {
              keys.push(input.feeBKey)
              keys.push(input.feeSKey)
            }
            if (data[input.GFEBlockKey] == 99) keys.push(input.feeBKey)
          },
        )
        CALCULATED_VALUE =
          dataSum(keys) +
          dataSum(['HUD1.Line703PBA', 'HUD1.Line704PBA', 'Loan.MIPFFPaidInCash']) +
          calculateSum([
            'Loan._GFEBlockTitleServices',
            'Loan._GFEBlockRecordingCharges',
            'Loan._GFEBlockTransferTaxes',
            'Loan._GFEBlockServicesYouCanShopFor',
            'Loan._HUD1AdjustedOriginationCharge',
            'Loan._GFEBlockEscrowDeposit',
          ]) +
          +(data['Loan.InterimInterestOV'] || calculateValue('Loan._InterimInterest') || 0) +
          +(
            data['PrepaidItems.0.PremiumOV'] ||
            +data['PrepaidItems.0.MonthsInAdvance'] * +data['PrepaidItems.0.Payment']
          ) +
          +(
            data['PrepaidItems.5.PremiumOV'] ||
            +data['PrepaidItems.5.MonthsInAdvance'] * +data['PrepaidItems.5.Payment']
          )
        break
      case 'HUD1.TotalSellerCharges':
        ;[...inputs['1102-1104'], ...inputs['1109-1200'], ...inputs['1206-1300'], ...inputs['1302-1400']].map(
          (input: any) => {
            if ((input.error && !ignoreEror) || data[input.pocKey]) return
            if (data[input.GFEBlockKey] == 99) keys.push(input.feeSKey)
          },
        )
        CALCULATED_VALUE = dataSum(['HUD1.Line703PBS', 'HUD1.Line704PBS', ...keys]) || 0.0
        break
      case 'Loan._GFEBlockEscrowDeposit':
        CALCULATED_VALUE =
          (data['PrepaidItems.0.ReservesOV']
            ? +data['PrepaidItems.0.ReservesOV']
            : +data['PrepaidItems.0.MonthsInReserve'] * +data['PrepaidItems.0.Payment']) +
          (data['PrepaidItems.1.ReservesOV']
            ? +data['PrepaidItems.1.ReservesOV']
            : +data['PrepaidItems.1.MonthsInReserve'] * +data['PrepaidItems.1.Payment']) +
          (data['PrepaidItems.2.ReservesOV']
            ? +data['PrepaidItems.2.ReservesOV']
            : +data['PrepaidItems.2.MonthsInReserve'] * +data['PrepaidItems.2.Payment']) +
          (data['PrepaidItems.3.ReservesOV']
            ? +data['PrepaidItems.3.ReservesOV']
            : +data['PrepaidItems.3.MonthsInReserve'] * +data['PrepaidItems.3.Payment']) +
          (data['PrepaidItems.4.ReservesOV']
            ? +data['PrepaidItems.4.ReservesOV']
            : +data['PrepaidItems.4.MonthsInReserve'] * +data['PrepaidItems.4.Payment']) +
          (data['PrepaidItems.5.ReservesOV']
            ? +data['PrepaidItems.5.ReservesOV']
            : +data['PrepaidItems.5.MonthsInReserve'] * +data['PrepaidItems.5.Payment']) +
          (data['PrepaidItems.6.ReservesOV']
            ? +data['PrepaidItems.6.ReservesOV']
            : +data['PrepaidItems.6.MonthsInReserve'] * +data['PrepaidItems.6.Payment']) +
          calculateAggregateAdjustment()
        break
      case 'Recording Fee Deed':
        inputs['govermentChargesChilds']?.map((input: any) => {
          if (input.error && !ignoreEror) return
          if (data[input['ClosingCostTypeKey']] == 24)
            CALCULATED_VALUE = +data[input['feeBKey']] + +data[input['feeSKey']]
        })
        break
      case 'Recording Fee Mortgage':
        inputs['govermentChargesChilds']?.map((input: any) => {
          if (input.error && !ignoreEror) return
          if (data[input['ClosingCostTypeKey']] == 35)
            CALCULATED_VALUE = +data[input['feeBKey']] + +data[input['feeSKey']]
        })
        break
      case 'Recording Fee Release':
        inputs['govermentChargesChilds']?.map((input: any) => {
          if (input.error && !ignoreEror) return
          if (data[input['ClosingCostTypeKey']] == 46)
            CALCULATED_VALUE = +data[input['feeBKey']] + +data[input['feeSKey']]
        })
        break
      case 'City/Country Deed':
        inputs['transferTaxChilds']?.map((input: any) => {
          if (input.error && !ignoreEror) return
          if (data[input['ClosingCostTypeKey']] == 17)
            CALCULATED_VALUE = +data[input['feeBKey']] + +data[input['feeSKey']]
        })
        break
      case 'City/Country Mortgage':
        inputs['transferTaxChilds']?.map((input: any) => {
          if (input.error && !ignoreEror) return
          if (data[input['ClosingCostTypeKey']] == 18)
            CALCULATED_VALUE = +data[input['feeBKey']] + +data[input['feeSKey']]
        })
        break
      case 'State Deed':
        inputs['transferTaxChilds']?.map((input: any) => {
          if (input.error && !ignoreEror) return
          if (data[input['ClosingCostTypeKey']] == 49)
            CALCULATED_VALUE = +data[input['feeBKey']] + +data[input['feeSKey']]
        })
        break
      case 'State Mortgage':
        inputs['transferTaxChilds']?.map((input: any) => {
          if (input.error && !ignoreEror) return
          if (data[input['ClosingCostTypeKey']] == 50)
            CALCULATED_VALUE = +data[input['feeBKey']] + +data[input['feeSKey']]
        })
        break
      case 'HUD1.disbursementTotal':
        for (let i = 1501; i < 1516; i++) {
          keys.push('HUD1.Line' + i + 'Amount')
        }
        CALCULATED_VALUE = dataSum(keys)
        break
      case 'HUD1.disbursementToBorrower':
        CALCULATED_VALUE =
          dataSum(['HUD1.Line202Calc', 'HUD1.CashFromBorrower']) -
          +calculateValue('HUD1.TotalBorrowerCharges') -
          calculateValue('HUD1.disbursementTotal')
        break
      case 'Loan.AggregateAdjustment':
        CALCULATED_VALUE = calculateAggregateAdjustment()
        break
      default:
    }
    return CALCULATED_VALUE
  }

  const calculateAggregateAdjustment = () => {
    const firstPaymentMonth = Number(formatDate(data['Loan.FirstPaymentDate'], 'M'))
    const firstPaymentYear = Number(formatDate(data['Loan.FirstPaymentDate'], 'YYYY'))
    const closingDateMonth = Number(formatDate(data['Status.SchedClosingDate'], 'M'))
    const closingDateYear = Number(formatDate(data['Status.SchedClosingDate'], 'YYYY'))

    let totalCush = 0
    let paymentToEscrow: number = 0
    let paymentFromEscrows: Array<number> = []
    for (let i = 0; i < 12; i++) {
      paymentFromEscrows.push(0)
    }
    for (let i = 0; i < 7; i++) {
      const disbursementSched = data[`PrepaidItems.${i}.DisbursementSched`]
      const payment = Number(data[`PrepaidItems.${i}.Payment`])
      const disbursementStartPur = data[`PrepaidItems.${i}.DisbursementStartPur`]
      const disbursementStartYear = data[`PrepaidItems.${i}.DisbursementStartYear`]
      const disbursementPeriods = data[`PrepaidItems.${i}.DisbursementPeriods`]
      const disbursementList = data[`PrepaidItems.${i}.DisbursementList`]?.split('|')
      const cushion = data[`PrepaidItems.${i}.CushionOV`] || data[`PrepaidItems.${i}._Cushion`] || 0
      if (disbursementSched > 1) totalCush += cushion * payment

      if (disbursementSched > 1) paymentToEscrow += payment
      if (disbursementSched == 2) {
        for (let j = 0; j < 12; j++) {
          paymentFromEscrows[j] += payment
        }
      } else if ([3, 4, 5].includes(Number(disbursementSched))) {
        if (disbursementStartPur == 0) continue
        let startMonth, startYear
        if (disbursementStartPur > 0 && disbursementStartPur < 13) {
          startMonth = disbursementStartPur
          startYear = disbursementStartYear
        } else {
          startMonth = closingDateMonth
          startYear = closingDateYear
        }
        let monthsInPeriod = 12
        if (disbursementSched == 3) monthsInPeriod = 3
        else if (disbursementSched == 4) monthsInPeriod = 6

        for (let j = (startMonth - firstPaymentMonth + 12) % 12; j < 12; j += monthsInPeriod) {
          if (!startYear || firstPaymentYear + (j + firstPaymentMonth > 12 ? 1 : 0) >= startYear) {
            paymentFromEscrows[j] += payment * monthsInPeriod
          }
        }
      } else if (disbursementSched == 6) {
        for (let j = 0; j < 12; j++) {
          if (disbursementPeriods[j] != '.') {
            const index = (j + 1 - firstPaymentMonth + 12) % 12
            paymentFromEscrows[index] += payment * (disbursementPeriods[j].charCodeAt(0) - 'A'.charCodeAt(0) + 1)
          }
        }
      } else if (disbursementSched == 7) {
        disbursementList?.map((v: any) => {
          const [date, amount] = v.split('~')
          const month = new Date(date).getMonth() + 1
          let year = new Date(date).getFullYear()
          if (month < firstPaymentMonth) year--
          if (year == firstPaymentYear) {
            const index = (month - firstPaymentMonth + 12) % 12
            paymentFromEscrows[index] += parseFloat(amount)
          }
        })
      }
    }
    let minBal = 1000000000
    let balance = 0
    for (let i = 0; i < 12; i++) {
      balance = balance + paymentToEscrow - paymentFromEscrows[i]
      if (minBal > balance) minBal = balance
    }

    let sum = totalCush - minBal
    for (let i = 0; i < 7; i++) {
      const prefix = `PrepaidItems.${i}.`
      if (['0', '1', '', 0, 1].includes(data[`${prefix}DisbursementSched`])) continue
      const monthInReserve = Number(data[`${prefix}MonthsInReserve`])
      const payment = Number(data[`${prefix}Payment`])
      const result = monthInReserve * payment
      sum -= result
    }

    return sum
  }

  const dataSum = (keys: Array<string>) => {
    let sum = 0
    keys.forEach((key) => {
      if (data[key]) sum += +data[key] || 0
    })
    return sum
  }

  const onUpdateMonthReserve = async () => {
    const temp = cloneDeep(data)
    let htmlContent =
      'Would you like to update the number of months in reserves?<div style="font-size: 16px; padding: 0 26px; text-align: left; margin-top: 12px;">'
    const titles = [
      inputs['insurance'][0].title,
      inputs['insurance'][1].title,
      data['PrepaidItems.2.NameOV'],
      inputs['insurance'][2].title,
      inputs['insurance'][3].title,
      inputs['insurance'][4].title,
      data['PrepaidItems.6.NameOV'],
    ]
    const calculatedMonths = calculateMonthReserve()
    calculatedMonths.map((v, index: number) => {
      if (temp[`PrepaidItems.${index}.MonthsInReserve`] != v) {
        htmlContent += `<p>The months in reserve for ${titles[index]} should be ${v}</p>`
      }
    })
    htmlContent += '</div>'
    const result = await confirm(htmlContent)
    if (!result) return
    calculatedMonths.map((v, index: number) => {
      temp[`PrepaidItems.${index}.MonthsInReserve`] = v == 0 ? '' : v
      if (v == 0) temp[`PrepaidItems.${index}.Payment`] = ''
    })
    setData(temp)
    setChanged(true)
  }

  const checkMonthReserve = () => {
    let result = false
    const calculatedMonths = calculateMonthReserve()
    for (let i = 0; i < 7; i++) {
      if (data[`PrepaidItems.${i}.MonthsInReserve`] != calculatedMonths[i]) {
        result = true
        break
      }
    }
    setIsUpdateMonthReserve(result)
  }

  const calculateMonthReserve = () => {
    const firstPaymentMonth = Number(formatDate(data['Loan.FirstPaymentDate'], 'M'))
    const firstPaymentYear = Number(formatDate(data['Loan.FirstPaymentDate'], 'YYYY'))

    const calculatedMonths = []
    for (let i = 0; i < 7; i++) {
      const disbursementSched = +data[`PrepaidItems.${i}.DisbursementSched`]
      const disbursementStartPur = +data[`PrepaidItems.${i}.DisbursementStartPur`]
      const disbursementStartYear = +data[`PrepaidItems.${i}.DisbursementStartYear`]
      const monthInReserve = +data[`PrepaidItems.${i}.MonthsInReserve`]
      // const disbursementPeriods = data[`PrepaidItems.${i}.DisbursementPeriods`]
      // const disbursementList = data[`PrepaidItems.${i}.DisbursementList`]?.split('|')
      const cushion = data[`PrepaidItems.${i}.CushionOV`] || data[`PrepaidItems.${i}._Cushion`] || 0
      let result: number = 0
      if ([0, 1].includes(Number(disbursementSched))) {
        result = 0
      } else if ([6, 7].includes(Number(disbursementSched))) {
        result = cushion
      } else if ([3, 4, 5].includes(Number(disbursementSched))) {
        if (disbursementStartPur == 0) result = monthInReserve
        else if (disbursementStartPur == 13) result = 1
        else if (
          disbursementStartYear > firstPaymentYear + 1 ||
          (disbursementStartYear == firstPaymentYear + 1 && firstPaymentMonth < disbursementStartPur + 1)
        )
          result = 0
        else {
          let monthsInPeriod = 12
          if (disbursementSched == 3) monthsInPeriod = 3
          else if (disbursementSched == 4) monthsInPeriod = 6

          let min = 100
          for (let i = 0; i < 12 / monthsInPeriod; i++) {
            const currentMonth = (disbursementStartPur + monthsInPeriod * (i - 1)) % 12
            const val = ((firstPaymentMonth % 12) - ((currentMonth + 1) % 12) + 12) % 12
            if (min > val) min = val
          }
          result = min
        }
        result += cushion
      }
      calculatedMonths.push(result)
    }
    return calculatedMonths
  }

  const calculateSum = (keys: Array<string>) => {
    let sum = 0
    keys.forEach((key) => {
      sum += +calculateValue(key)
    })
    return sum
  }

  const closeMIPFundingModal = (save: boolean, detailData: any) => {
    if (save) {
      let newData = cloneDeep(data)
      Object.keys(detailData).map((key) => {
        if (data[key] !== detailData[key]) {
          newData = onBlur(key, detailData[key], '', newData)
        }
      })
      setData(newData)
      setChanged(true)
    }
    setShowMIPFundingModal(false)
    setShowInterimInterestModal(false)
  }

  const showInputValue = (value: any, key: string) => {
    if (key !== focusedKey) {
      value = getPrice2decimal(value, false, true)
      return convertNegative2Parentheses(value)
    }
    return value
  }

  const onFeeDelete = async (ccid: number, lineId: number, title: string) => {
    const content = (
      <>
        <div className="mb-2">Are you sure want to remove this Closing Cost?</div>
        <div className="mb-4 italic">{`${title} (${lineId})`}</div>
      </>
    )
    const isDelete = await confirm(content, {}, 2)
    if (!isDelete) return
    setIsLoading(true)
    await deleteClosingCost(ccid || lineId)
    setIsLoading(false)
    onDeleteFee()
  }

  const renderHUD1Input = (input: any, key?: number) => {
    const {
      type,
      title,
      titleBold,
      titleKey,
      fromKey,
      toKey,
      valueKey,
      overrideKey,
      canOverride,
      defaultValueKey,
      partyType,
      feeMultipleKey,
      feeBKey,
      feeSKey,
      pocKey,
      isChild,
      inputWidth,
      monthKey,
      partyKey,
      TRIDBlockKey,
      GFEBlockKey,
      ClosingCostTypeKey,
      PaidToTypeKey,
      FinancedKey,
      ResponsiblePartyTypeKey,
      NetFromWireKey,
      NotCountedKey,
      ProviderChosenByBorrowerKey,
      PaidByOtherTypeKey,
      PPFCKey,
      lineNoKey,
      lineIdKey,
      PointsAndFeesAmountOVKey,
      PaidToNameKey,
      calculatedValue,
      reserveOVKey,
      contentType,
      PeriodicPaymentAmountKey,
      DisbursementSchedKey,
      DisbursementStartPurKey,
      CushionKey,
      CushionOVKey,
      MonthsInAdvanceKey,
      PremiumOVKey,
      PremiumPBSDesired,
      PremiumNetFromWireKey,
      PremiumPOCKey,
      ReservesPOCKey,
      PremiumPaidToTypeKey,
      PremiumPointsAndFeesAmountOV,
      ReservesPointsAndFeesAmountOV,
      QMATRNotesKey,
      PrepaidItemTypeKey,
      ReservesPaidByOtherTypeKey,
      PremiumPaidByOtherTypeKey,
      MISMOPrepaidItemTypeOVKey,
      DisbursementStartYearKey,
      DisbursementPeriodsKey,
      DisbursementListKey,
      isPOCDisabled,
      ccid,
      error,
    } = input
    if (!ignoreEror && error) return
    let VALUE = data[valueKey]
    const OVVALUE = data[overrideKey]
    let CALCULATED_VALUE: any = calculateValue(valueKey)
    if (valueKey != 'Loan._DailyInterest') CALCULATED_VALUE = getPrice2decimal(CALCULATED_VALUE, false, true)
    VALUE = CALCULATED_VALUE || calculatedValue || VALUE
    if (type === HUD1InputType.onlyTitle) {
      // only title
      return (
        <div className={`flex gap-2 mt-4 ${titleBold ? 'font-semibold' : ''}`}>
          {!isNaN(Number(key)) && <div className="w-[28px]">{key}.</div>} {title}
        </div>
      )
    }
    if (type === HUD1InputType.titleInputValue) {
      // title + input value
      return (
        <div className="flex flex-wrap justify-end items-center gap-x-4 gap-y-1">
          <div className="flex-1">
            <div className="flex gap-2">
              <div className="w-[28px] cursor-pointer hover:underline" onClick={() => showHistory([valueKey])}>
                {key}.
              </div>{' '}
              {title}
            </div>
          </div>
          <div className="flex-end">
            <input
              className={`px-2 text-right border w-[120px] bg-${noteColors[notes[valueKey]?.flag]} `}
              value={showInputValue(VALUE, valueKey)}
              onBlur={(e) => {
                onBlur(valueKey, e.target.value, 'number')
              }}
              onFocus={() => onFocus(valueKey)}
              onChange={(e) => onChange(valueKey, e.target.value)}
              onContextMenu={(e) => handleContextMenu(e, valueKey, data[valueKey])}
            />
          </div>
        </div>
      )
    }
    if (type === HUD1InputType.titleCalculatedValue) {
      // title + calculated value
      return (
        <div className={`flex flex-wrap justify-end items-center gap-x-4 gap-y-1 ${isChild ? 'pl-6' : ''}`}>
          <div className="flex-1 flex justify-between items-center">
            <div className={`flex gap-2 ${titleBold ? 'font-semibold' : ''}`}>
              {key && (
                <div
                  className={`w-[28px] cursor-pointer hover:underline`}
                  onClick={() => showHistory([valueKey, overrideKey].filter((v) => !!v))}
                >
                  {key}.
                </div>
              )}{' '}
              {title}
            </div>
            {canOverride && (
              <span
                className="cursor-pointer hover:text-shade-blue"
                onClick={() =>
                  setOVData({
                    title,
                    overrideKey,
                    calcValue: CALCULATED_VALUE || data[valueKey],
                    ovValue: data[overrideKey] || '',
                  })
                }
              >
                <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
              </span>
            )}
          </div>
          <div>
            <input
              disabled={true}
              className={`px-2 text-right border cursor-not-allowed w-[120px] ${OVVALUE ? 'bg-red-100' : ''} bg-${
                noteColors[notes[valueKey]?.flag]
              }`}
              value={convertNegative2Parentheses(getPrice2decimal(OVVALUE || VALUE, false, true))}
              onContextMenu={(e) =>
                handleContextMenu(
                  e,
                  valueKey,
                  convertNegative2Parentheses(getPrice2decimal(OVVALUE || VALUE, false, true)),
                )
              }
            />
          </div>
        </div>
      )
    }
    if (type === HUD1InputType.inputTitleInputValue) {
      // input title + input value
      return (
        <div className="flex flex-wrap justify-end items-center gap-x-4 gap-y-1">
          <div className="flex flex-1 gap-2">
            <div className="w-[28px]  cursor-pointer hover:underline" onClick={() => showHistory([titleKey, valueKey])}>
              {key}.
            </div>
            <input
              className={`px-2 border flex-1 bg-${noteColors[notes[titleKey]?.flag]}`}
              value={data[titleKey]}
              onChange={(e) => onChange(titleKey, e.target.value)}
              onContextMenu={(e) => handleContextMenu(e, titleKey, data[titleKey])}
            />
          </div>
          <div className="">
            <input
              className={`px-2 text-right border w-[120px] bg-${noteColors[notes[valueKey]?.flag]}`}
              value={showInputValue(VALUE, valueKey)}
              onBlur={(e) => {
                onBlur(valueKey, e.target.value, 'number')
              }}
              onFocus={() => onFocus(valueKey)}
              onChange={(e) => onChange(valueKey, e.target.value)}
              onContextMenu={(e) => handleContextMenu(e, valueKey, data[valueKey])}
            />
          </div>
        </div>
      )
    }
    if (type === HUD1InputType.inputFromToValue) {
      // input from & to date + input value
      return (
        <div className="flex flex-wrap justify-end gap-2">
          <div
            className="w-[28px]  cursor-pointer hover:underline"
            onClick={() => showHistory([fromKey, toKey, valueKey])}
          >
            {key}.
          </div>
          <div className="flex flex-wrap flex-1 gap-2 items-center">
            <div className="w-[75px]">{title}</div>
            <input
              className="px-0 border border-gray-200 text-[14px] w-[120px] py-0 placeholder-gray-500"
              value={data[fromKey]}
              type="date"
              onChange={(e) => onChange(fromKey, e.target.value)}
            />
            <span>to</span>
            <input
              className="px-0 border border-gray-200 text-[14px] w-[120px] py-0 placeholder-gray-500"
              value={data[toKey]}
              type="date"
              onChange={(e) => onChange(toKey, e.target.value)}
            />
          </div>
          <div className="">
            <input
              className="px-2 text-right border w-[120px]"
              value={showInputValue(VALUE, valueKey)}
              onBlur={(e) => {
                onBlur(valueKey, e.target.value, 'number')
              }}
              onFocus={() => onFocus(valueKey)}
              onChange={(e) => onChange(valueKey, e.target.value)}
            />
          </div>
        </div>
      )
    }
    if (type == HUD1InputType.inputParty) {
      return (
        <div className="flex items-center gap-2">
          <div className="w-[28px] cursor-pointer hover:underline" onClick={() => showHistory([valueKey])}>
            {key}.
          </div>
          <div className="flex gap-2 items-center">
            {title}
            <input
              className={`px-2 text-right border w-[120px] bg-${noteColors[notes[valueKey]?.flag]}`}
              value={showInputValue(data[valueKey], valueKey)}
              onBlur={(e) => {
                onBlur(valueKey, e.target.value, 'number')
              }}
              onFocus={() => onFocus(valueKey)}
              onChange={(e) => onChange(valueKey, e.target.value)}
              onContextMenu={(e) => handleContextMenu(e, valueKey, data[valueKey])}
            />
          </div>
          <div className="flex gap-2 items-center">
            <span>to</span>
            <input
              className={`px-2 border w-[240px] bg-${noteColors[notes[defaultValueKey]?.flag]}`}
              value={data[overrideKey] || data[defaultValueKey]}
              disabled={true}
              onContextMenu={(e) => handleContextMenu(e, defaultValueKey, data[overrideKey] || data[defaultValueKey])}
            />
            <span
              className="cursor-pointer hover:text-shade-blue"
              onClick={() =>
                setOVData({
                  title: partyType.name,
                  overrideKey: overrideKey,
                  calcValue: data[defaultValueKey],
                  ovValue: data[overrideKey] || '',
                })
              }
            ></span>
            <Link
              className="flex items-center gap-3 hover:underline hover:text-shade-blue cursor-pointer w-fit"
              to={`/parties/${loanNumber}?edit=${partyType}&redirectTo=${encodeURI(
                `/closing_screen/${loanNumber}?menu=${isHud1a ? 'hud1A' : 'hud1Page2'}`,
              )}`}
            >
              <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
            </Link>
          </div>
        </div>
      )
    }
    if (type == HUD1InputType.inputInputParty) {
      return (
        <div className="flex items-center gap-2">
          <div
            className="w-[28px] cursor-pointer hover:underline"
            onClick={() => showHistory([valueKey, titleKey].filter((v) => !!v))}
          >
            {key}.
          </div>
          <div className="flex gap-2 items-center">
            {title}
            <input
              className="px-2 text-right border w-[120px]"
              value={showInputValue(data[valueKey], valueKey)}
              onBlur={(e) => {
                onBlur(valueKey, e.target.value, 'number')
              }}
              onFocus={() => onFocus(valueKey)}
              onChange={(e) => onChange(valueKey, e.target.value)}
            />
          </div>
          <div className="flex gap-2 items-center">
            <span>to</span>
            <input
              className={`px-2 border w-[240px]`}
              value={data[titleKey]}
              onChange={(e) => onChange(titleKey, e.target.value)}
            />
          </div>
        </div>
      )
    }
    if (type === HUD1InputType.title3InputsCheckbox) {
      return (
        <div className={`flex items-center justify-between ${isChild ? 'pl-6' : ''}`}>
          <div className="flex">
            <span
              className="cursor-pointer hover:underline"
              onClick={() =>
                showHistory(
                  [
                    (data[lineNoKey] == 801 && [32, 34].includes(data[ClosingCostTypeKey])) || data[lineNoKey] == 802
                      ? feeMultipleKey
                      : '',
                    feeBKey,
                    feeSKey,
                    pocKey,
                  ].filter((v) => !!v),
                )
              }
            >
              {key}
            </span>
            .
            <span
              className="cursor-pointer"
              onClick={() =>
                setClosingCostsDetailData({
                  hudNo: { value: data[lineNoKey], key: lineNoKey },
                  lineName: { value: title, key: 'title' },
                  isPoints: {
                    value:
                      (data[lineNoKey] == 801 && [32, 34].includes(data[ClosingCostTypeKey])) || data[lineNoKey] == 802,
                    key: 'isPoints',
                  },
                  points: { value: data[feeMultipleKey], key: feeMultipleKey },
                  borrowerAmount: { value: data[feeBKey], key: feeBKey },
                  sellerAmount: { value: data[feeSKey], key: feeSKey },
                  TRIDBlock: { value: data[TRIDBlockKey], key: TRIDBlockKey },
                  GFEBlock: { value: data[GFEBlockKey], key: GFEBlockKey },
                  ClosingCostType: { value: data[ClosingCostTypeKey], key: ClosingCostTypeKey },
                  PaidToType: { value: data[PaidToTypeKey], key: PaidToTypeKey },
                  Financed: { value: data[FinancedKey], key: FinancedKey },
                  ResponsiblePartyType: { value: data[ResponsiblePartyTypeKey], key: ResponsiblePartyTypeKey },
                  NetFromWire: { value: data[NetFromWireKey], key: NetFromWireKey },
                  NotCounted: { value: data[NotCountedKey], key: NotCountedKey },
                  ProviderChosenByBorrower: {
                    value: data[ProviderChosenByBorrowerKey],
                    key: ProviderChosenByBorrowerKey,
                  },
                  PaidByOtherType: { value: data[PaidByOtherTypeKey], key: PaidByOtherTypeKey },
                  poc: { value: data[pocKey], key: pocKey },
                  PPFC: { value: data[PPFCKey], key: PPFCKey },
                  lineId: { value: data[lineIdKey], key: lineIdKey },
                  PointsAndFeesAmountOV: { value: data[PointsAndFeesAmountOVKey], key: PointsAndFeesAmountOVKey },
                  PaidTo: { value: data[PaidToNameKey], key: PaidToNameKey },
                  isPOCDisabled: { value: isPOCDisabled, key: '' },
                  partyType: { value: partyType, key: '' },
                  partyName: { value: data[defaultValueKey], key: '' },
                  ccid: { value: ccid, key: '' },
                })
              }
            >
              <PencilSquareIcon className="w-4 h-4" />
            </span>
            {data[titleKey]}
          </div>
          <div className="flex gap-1">
            <div className="w-[80px]">
              {' '}
              {((data[lineNoKey] == 801 && [32, 34].includes(data[ClosingCostTypeKey])) || data[lineNoKey] == 802) && (
                <input
                  className={`w-full px-2 text-right border bg-${noteColors[notes[feeMultipleKey]?.flag]}`}
                  value={showInputValue(data[feeMultipleKey], feeMultipleKey)}
                  onBlur={(e) => {
                    onBlur(feeMultipleKey, e.target.value, 'number')
                  }}
                  onFocus={() => onFocus(feeMultipleKey)}
                  onChange={(e) => onChange(feeMultipleKey, e.target.value)}
                  onContextMenu={(e) => handleContextMenu(e, feeMultipleKey, data[feeMultipleKey])}
                />
              )}
            </div>
            <input
              className={`px-2 text-right border w-[80px] bg-${noteColors[notes[feeBKey]?.flag]}`}
              value={showInputValue(data[feeBKey], feeBKey)}
              onBlur={(e) => {
                onBlur(feeBKey, e.target.value, 'number')
              }}
              onFocus={() => onFocus(feeBKey)}
              onChange={(e) => onChange(feeBKey, e.target.value)}
              disabled={data[feeMultipleKey] ? true : false}
              onContextMenu={(e) => handleContextMenu(e, feeBKey, data[feeBKey])}
            />
            <span>B</span>
            <input
              className={`px-2 text-right border w-[80px] bg-${noteColors[notes[feeSKey]?.flag]}`}
              value={showInputValue(data[feeSKey], feeSKey)}
              onBlur={(e) => {
                onBlur(feeSKey, e.target.value, 'number')
              }}
              onFocus={() => onFocus(feeSKey)}
              onChange={(e) => onChange(feeSKey, e.target.value)}
              onContextMenu={(e) => handleContextMenu(e, feeSKey, data[feeSKey])}
            />
            <span>{PaidByInitialMap[data[PaidByOtherTypeKey] || 0]}</span>
            {!isPOCDisabled && (
              <>
                <span>POC</span>
                <Checkbox
                  className={`w-fit`}
                  id={'Calc'}
                  key={'Calc'}
                  value={data[pocKey]}
                  onChange={(e) => onChange(pocKey, e)}
                />
              </>
            )}
          </div>
        </div>
      )
    }
    if (type === HUD1InputType.titlePartyInputs) {
      return (
        <div className={`flex flex-1 items-center justify-between ${isChild ? 'pl-6' : ''}`}>
          <div className="flex">
            <span
              className="cursor-pointer hover:underline"
              onClick={() => showHistory([partyKey, feeBKey, feeSKey].filter((v) => !!v))}
            >
              {key}
            </span>
            .
            <span
              className="cursor-pointer"
              onClick={() =>
                setClosingCostsDetailData({
                  hudNo: { value: data[lineNoKey], key: lineNoKey },
                  lineName: { value: data[titleKey], key: titleKey },
                  isPoints: {
                    value:
                      (data[lineNoKey] == 801 && [32, 34].includes(data[ClosingCostTypeKey])) || data[lineNoKey] == 802,
                    key: 'isPoints',
                  },
                  points: { value: data[feeMultipleKey], key: feeMultipleKey },
                  borrowerAmount: { value: data[feeBKey], key: feeBKey },
                  sellerAmount: { value: data[feeSKey], key: feeSKey },
                  TRIDBlock: { value: data[TRIDBlockKey], key: TRIDBlockKey },
                  GFEBlock: { value: data[GFEBlockKey], key: GFEBlockKey },
                  ClosingCostType: { value: data[ClosingCostTypeKey], key: ClosingCostTypeKey },
                  PaidToType: { value: data[PaidToTypeKey], key: PaidToTypeKey },
                  Financed: { value: data[FinancedKey], key: FinancedKey },
                  ResponsiblePartyType: { value: data[ResponsiblePartyTypeKey], key: ResponsiblePartyTypeKey },
                  NetFromWire: { value: data[NetFromWireKey], key: NetFromWireKey },
                  NotCounted: { value: data[NotCountedKey], key: NotCountedKey },
                  ProviderChosenByBorrower: {
                    value: data[ProviderChosenByBorrowerKey],
                    key: ProviderChosenByBorrowerKey,
                  },
                  PaidByOtherType: { value: data[PaidByOtherTypeKey], key: PaidByOtherTypeKey },
                  poc: { value: data[pocKey], key: pocKey },
                  PPFC: { value: data[PPFCKey], key: PPFCKey },
                  lineId: { value: data[lineIdKey], key: lineIdKey },
                  PointsAndFeesAmountOV: { value: data[PointsAndFeesAmountOVKey], key: PointsAndFeesAmountOVKey },
                  PaidTo: { value: data[PaidToNameKey], key: PaidToNameKey },
                  isPOCDisabled: { value: isPOCDisabled, key: '' },
                  partyType: { value: partyType, key: '' },
                  partyName: { value: data[defaultValueKey], key: '' },
                  ccid: { value: ccid, key: '' },
                })
              }
            >
              <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
            </span>
            {data[titleKey]}
          </div>
          <div className="flex gap-1">
            <div className="flex gap-2 items-center">
              <span>to</span>
              {partyType && (
                <div className="w-[140px] flex gap-1">
                  <span
                    className={`cursor-pointer hover:text-shade-blue flex-1 overflow-hidden bg-${
                      noteColors[notes[PaidToNameKey]?.flag]
                    }`}
                    onClick={() =>
                      setOVData({
                        title: partyType.name,
                        overrideKey: PaidToNameKey,
                        calcValue: data[defaultValueKey],
                        ovValue: data[PaidToNameKey] || '',
                      })
                    }
                    onContextMenu={(e) =>
                      handleContextMenu(e, PaidToNameKey, data[PaidToNameKey] || data[defaultValueKey])
                    }
                  >
                    <span
                      className={`px-2 border cursor-not-allowed inline-flex w-full overflow-auto  no-scrollbar whitespace-nowrap text-overflow-ellipsis ${
                        data[PaidToNameKey] ? 'bg-red-100' : ''
                      }`}
                    >
                      {data[PaidToNameKey] || data[defaultValueKey]}
                    </span>
                  </span>
                  <Link
                    className="flex items-center gap-3 hover:underline hover:text-shade-blue cursor-pointer w-fit"
                    to={`/parties/${loanNumber}?edit=${partyType}&redirectTo=${encodeURI(
                      `/closing_screen/${loanNumber}?menu=${isHud1a ? 'hud1A' : 'hud1Page2'}`,
                    )}`}
                  >
                    <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                  </Link>
                </div>
              )}
              {partyKey && (
                <input
                  className={`px-2 border w-[140px] bg-${noteColors[notes[partyKey]?.flag]}`}
                  value={data[partyKey]}
                  onChange={(e) => onChange(partyKey, e.target.value)}
                  onContextMenu={(e) => handleContextMenu(e, partyKey, data[partyKey])}
                />
              )}
            </div>
            <input
              className={`px-2 text-right border w-[80px] bg-${noteColors[notes[feeBKey]?.flag]}`}
              value={showInputValue(data[feeBKey], feeBKey)}
              onBlur={(e) => {
                onBlur(feeBKey, e.target.value, 'number')
              }}
              onFocus={() => onFocus(feeBKey)}
              onChange={(e) => onChange(feeBKey, e.target.value)}
              onContextMenu={(e) => handleContextMenu(e, feeBKey, data[feeBKey])}
            />
            <span>B</span>
            <input
              className={`px-2 text-right border w-[80px] bg-${noteColors[notes[feeSKey]?.flag]}`}
              value={showInputValue(data[feeSKey], feeSKey)}
              onBlur={(e) => {
                onBlur(feeSKey, e.target.value, 'number')
              }}
              onFocus={() => onFocus(feeSKey)}
              onChange={(e) => onChange(feeSKey, e.target.value)}
              onContextMenu={(e) => handleContextMenu(e, feeSKey, data[feeSKey])}
            />
            <span>{PaidByInitialMap[data[PaidByOtherTypeKey] || 0]}</span>
            <Tooltip
              message={
                data[pocKey]
                  ? `${data[feeBKey] && data[feeBKey] + 'B*'}${
                      data[feeBKey] && data[feeSKey] && data[PaidByOtherTypeKey] == 0 ? ' + ' : ''
                    }${data[feeSKey] && data[PaidByOtherTypeKey] == 0 ? data[feeSKey] + 'S*' : ''}`
                  : `${getPrice1or2decimal(+data[feeBKey] + +data[feeSKey])}`
              }
            >
              <input
                className="px-2 text-right border w-[50px]"
                value={
                  data[pocKey]
                    ? `${data[feeBKey] && data[feeBKey] + 'B*'}${
                        data[feeBKey] && data[feeSKey] && data[PaidByOtherTypeKey] == 0 ? ' + ' : ''
                      }${data[feeSKey] && data[PaidByOtherTypeKey] == 0 ? data[feeSKey] + 'S*' : ''}`
                    : `${getPrice2decimal(+data[feeBKey] + +data[feeSKey], false, true)}`
                }
                disabled={true}
              ></input>
            </Tooltip>
            {!isPOCDisabled && (
              <>
                <span>POC</span>
                <Checkbox
                  className={`w-fit`}
                  id={'Calc'}
                  key={'Calc'}
                  value={data[pocKey]}
                  onChange={(e) => onChange(pocKey, e)}
                />
              </>
            )}
          </div>
        </div>
      )
    }
    if (type === HUD1InputType.normalInput) {
      return (
        <input
          className={`px-2 text-right border w-[${inputWidth || '120px'}] bg-${noteColors[notes[valueKey]?.flag]}`}
          value={contentType == 'number' ? showInputValue(data[valueKey], valueKey) : data[valueKey]}
          onBlur={(e) => {
            onBlur(valueKey, e.target.value, contentType == 'text' ? '' : 'number')
          }}
          onFocus={() => onFocus(valueKey)}
          onChange={(e) => onChange(valueKey, e.target.value)}
          onContextMenu={(e) => handleContextMenu(e, valueKey, data[valueKey])}
        />
      )
    }
    if (type === HUD1InputType.calculatedInput) {
      return (
        <>
          {canOverride && (
            <span
              className="cursor-pointer hover:text-shade-blue"
              onClick={() =>
                setOVData({
                  title,
                  overrideKey,
                  calcValue: CALCULATED_VALUE || calculatedValue || data[valueKey],
                  ovValue: data[overrideKey] || '',
                })
              }
              onContextMenu={(e) =>
                handleContextMenu(
                  e,
                  valueKey,
                  valueKey == 'Loan._DailyInterest'
                    ? convertNegative2Parentheses(OVVALUE || VALUE)
                    : convertNegative2Parentheses(getPrice2decimal(OVVALUE || VALUE || 0, false, true)),
                )
              }
            >
              <span
                className={`px-2 text-right border cursor-not-allowed inline-block w-[80px] ${
                  OVVALUE ? 'bg-red-100' : ''
                } bg-${noteColors[notes[valueKey]?.flag]}`}
              >
                {valueKey == 'Loan._DailyInterest'
                  ? convertNegative2Parentheses(OVVALUE || VALUE)
                  : convertNegative2Parentheses(getPrice2decimal(OVVALUE || VALUE || 0, false, true))}
              </span>
            </span>
          )}
        </>
      )
    }
    if (type === HUD1InputType.titleInputCalculatedValueInput) {
      return (
        <div className="flex justify-between gap-2">
          <div className="flex flex-1 flex-wrap justify-start items-center gap-x-4 gap-y-1">
            {key && (
              <span
                className="cursor-pointer hover:underline"
                onClick={() =>
                  showHistory([
                    partyKey ? MonthsInAdvanceKey : monthKey,
                    partyKey ? partyKey : feeMultipleKey,
                    feeBKey,
                    feeSKey,
                    reserveOVKey,
                  ])
                }
              >
                {key + '.'}
              </span>
            )}{' '}
            {title != 'Mortgage Insurance' ? (
              <span
                className="cursor-pointer"
                onClick={() =>
                  setPrepaidItemsDetailData({
                    title: [3, 7].includes(data[PrepaidItemTypeKey]) ? data[titleKey] : title,
                    payment: { value: data[feeMultipleKey], key: feeMultipleKey },
                    periodicPayment: { value: data[PeriodicPaymentAmountKey], key: PeriodicPaymentAmountKey },
                    disbSched: { value: data[DisbursementSchedKey], key: DisbursementSchedKey },
                    starting: { value: data[DisbursementStartPurKey], key: DisbursementStartPurKey },
                    cushion: { value: data[CushionKey], key: CushionKey },
                    cushionOV: { value: data[CushionOVKey], key: CushionOVKey },
                    monthsInAdvance: { value: data[MonthsInAdvanceKey], key: MonthsInAdvanceKey },
                    premiumOV: { value: data[PremiumOVKey], key: PremiumOVKey },
                    premiumPBS: { value: data[PremiumPBSDesired], key: PremiumPBSDesired },
                    premiumPOC: { value: data[PremiumPOCKey], key: PremiumPOCKey },
                    netFromWire: { value: data[PremiumNetFromWireKey], key: PremiumNetFromWireKey },
                    paidTo: { value: data[PremiumPaidToTypeKey], key: PremiumPaidToTypeKey },
                    premPointsAndFeesOV: {
                      value: data[PremiumPointsAndFeesAmountOV],
                      key: PremiumPointsAndFeesAmountOV,
                    },
                    monthsInReserve: { value: data[monthKey], key: monthKey },
                    reserveOV: { value: data[reserveOVKey], key: reserveOVKey },
                    reservePBS: { value: data[feeSKey], key: feeSKey },
                    reservesPOC: { value: data[ReservesPOCKey], key: ReservesPOCKey },
                    resPointsAndFeesOV: {
                      value: data[ReservesPointsAndFeesAmountOV],
                      key: ReservesPointsAndFeesAmountOV,
                    },
                    atrNotes: { value: data[QMATRNotesKey], key: QMATRNotesKey },
                    premiumPaidByOther: { value: data[PremiumPaidByOtherTypeKey], key: PremiumPaidByOtherTypeKey },
                    reservePaidByOther: { value: data[ReservesPaidByOtherTypeKey], key: ReservesPaidByOtherTypeKey },
                    prepaidItemType: { value: data[MISMOPrepaidItemTypeOVKey], key: MISMOPrepaidItemTypeOVKey },
                    disbStartYear: { value: data[DisbursementStartYearKey], key: DisbursementStartYearKey },
                    disbPeriods: { value: data[DisbursementPeriodsKey], key: DisbursementPeriodsKey },
                    disbList: { value: data[DisbursementListKey], key: DisbursementListKey },
                  })
                }
              >
                <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
              </span>
            ) : (
              <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
            )}
            {titleKey ? (
              <>
                <input
                  className={`px-2 text-right border w-[120px]`}
                  value={data[titleKey]}
                  onChange={(e) => onChange(titleKey, e.target.value)}
                />
                <span
                  className="cursor-pointer hover:underline"
                  onClick={() =>
                    showHistory([
                      partyKey ? MonthsInAdvanceKey : monthKey,
                      partyKey ? partyKey : feeMultipleKey,
                      feeBKey,
                      feeSKey,
                      reserveOVKey,
                    ])
                  }
                >
                  <ClockIcon className="w-4 h-4" />
                </span>
              </>
            ) : !key ? (
              <span
                className="cursor-pointer hover:underline"
                onClick={() =>
                  showHistory([
                    partyKey ? MonthsInAdvanceKey : monthKey,
                    partyKey ? partyKey : feeMultipleKey,
                    feeBKey,
                    feeSKey,
                    reserveOVKey,
                  ])
                }
              >
                {title}
              </span>
            ) : (
              title
            )}
          </div>
          <div className="flex flex-1 gap-1">
            <input
              className={`px-2 text-right border w-12 bg-${
                noteColors[notes[partyKey ? MonthsInAdvanceKey : monthKey]?.flag]
              }`}
              value={partyKey ? data[MonthsInAdvanceKey] : data[monthKey]}
              onChange={(e) => onChange(partyKey ? MonthsInAdvanceKey : monthKey, e.target.value)}
              onContextMenu={(e) =>
                handleContextMenu(
                  e,
                  partyKey ? MonthsInAdvanceKey : monthKey,
                  partyKey ? data[MonthsInAdvanceKey] : data[monthKey],
                )
              }
            />
            <span>mo </span>
            {partyKey ? (
              <>
                <span>. to</span>
                <input
                  className={`px-2 text-right border w-[120px] bg-${noteColors[notes[partyKey]?.flag]}`}
                  value={data[partyKey]}
                  onChange={(e) => onChange(partyKey, e.target.value)}
                  onContextMenu={(e) => handleContextMenu(e, partyKey, data[partyKey])}
                />
              </>
            ) : (
              <>
                <span>×</span>
                <input
                  className={`px-2 text-right border w-[80px] bg-${noteColors[notes[feeMultipleKey]?.flag]}`}
                  value={showInputValue(data[feeMultipleKey], feeMultipleKey)}
                  onBlur={(e) => {
                    onBlur(feeMultipleKey, e.target.value, 'number')
                  }}
                  onFocus={() => onFocus(feeMultipleKey)}
                  onChange={(e) => onChange(feeMultipleKey, e.target.value)}
                  onContextMenu={(e) => handleContextMenu(e, feeMultipleKey, data[feeMultipleKey])}
                />
                <span>
                  {DisbursementSchedUnitMap[data[DisbursementSchedKey]]}{' '}
                  {[3, 4, 5].includes(data[DisbursementSchedKey]) &&
                    DisbursementStartPurMap[data[DisbursementStartPurKey]]?.slice(0, 3)}
                </span>
              </>
            )}
          </div>
          <div className="flex flex-1 flex-wrap justify-end gap-1">
            <input
              className={`px-2 text-right border w-[80px] bg-${
                noteColors[notes[partyKey ? PremiumOVKey : reserveOVKey]?.flag]
              }`}
              value={getPrice2decimal(
                !partyKey
                  ? data[reserveOVKey]
                    ? +data[reserveOVKey] - +data[feeSKey]
                    : +data[monthKey] * +data[feeMultipleKey] - +data[feeSKey]
                  : data[PremiumOVKey]
                  ? +data[PremiumOVKey] - +data[PremiumPBSDesired]
                  : +data[MonthsInAdvanceKey] * +data[feeMultipleKey] - +data[PremiumPBSDesired],
                false,
                true,
              )}
              disabled={true}
              onContextMenu={(e) =>
                handleContextMenu(
                  e,
                  partyKey ? PremiumOVKey : reserveOVKey,
                  getPrice2decimal(
                    !partyKey
                      ? data[reserveOVKey]
                        ? +data[reserveOVKey] - +data[feeSKey]
                        : +data[monthKey] * +data[feeMultipleKey] - +data[feeSKey]
                      : data[PremiumOVKey]
                      ? +data[PremiumOVKey] - +data[PremiumPBSDesired]
                      : +data[MonthsInAdvanceKey] * +data[feeMultipleKey] - +data[PremiumPBSDesired],
                    false,
                    true,
                  ),
                )
              }
            />
            B
            <input
              className={`px-2 text-right border w-[80px] bg-${
                noteColors[notes[partyKey ? PremiumPBSDesired : feeSKey]?.flag]
              }`}
              value={showInputValue(
                data[partyKey ? PremiumPBSDesired : feeSKey],
                partyKey ? PremiumPBSDesired : feeSKey,
              )}
              onBlur={(e) => {
                onBlur(partyKey ? PremiumPBSDesired : feeSKey, e.target.value, 'number')
              }}
              onFocus={() => onFocus(partyKey ? PremiumPBSDesired : feeSKey)}
              onChange={(e) => onChange(partyKey ? PremiumPBSDesired : feeSKey, e.target.value)}
              onContextMenu={(e) =>
                handleContextMenu(
                  e,
                  partyKey ? PremiumPBSDesired : feeSKey,
                  data[partyKey ? PremiumPBSDesired : feeSKey],
                )
              }
            />
            S
          </div>
        </div>
      )
    }
    if (type === HUD1InputType.radioGroup) {
      return (
        <RadioGroup
          id={`option-${title}`}
          name={`option-${title}`}
          title={title}
          options={{
            custom: 'Loan With MIP/FF',
            base: 'Base Loan',
          }}
          value={data[valueKey] == 1 ? 'custom' : 'base'}
          onChange={(v) => onChange(valueKey, v == 'base' ? 0 : 1)}
          className="!pt-0"
          isPlain
          noBorder
          noPadding
        />
      )
    }
  }
  const showHistory = async (keys: Array<string>) => {
    const options = {
      table: 'HUD1',
      field: keys.join(', '),
      keys: {
        field: keys,
      },
      renderValue: (data: any) => <span dangerouslySetInnerHTML={{ __html: data }} />,
    }
    keys = keys.filter((key) => key)
    openAuditLog(options)
  }

  const renderSectionHeader = (title: string, type: string = 'table') => {
    if (type == 'table')
      return (
        <tr>
          <td className="bg-blue-100 p-2">{title}</td>
          <td className="bg-blue-100 p-2">Borrower</td>
          {pageType != 'hud1A' && <td className="bg-blue-100 p-2">Seller</td>}
        </tr>
      )
    else return <p className="bg-blue-100 p-2 md:col-span-4 col-span-1">{title}</p>
  }

  const onDownload = async () => {
    let pdfData, res: any
    if (isHud1a) {
      setIsLoading(true)
      pdfData = await downloadHUD1PDF('hud1A')
    } else {
      res = await confirmOptions('Print HUD1', [
        { name: 'Page 2', value: 0 },
        { name: 'All Pages', value: 1 },
      ])
      if (res === false) return
      setIsLoading(true)
      pdfData = await downloadHUD1PDF(res === 0 ? 'hud1-Page2' : 'hud1-all')
    }
    downloadFile(
      `HUD1${isHud1a ? 'A' : ''}${res === 0 ? '-Page2' : ''} ${loanNumber}(${moment()
        .tz('America/New_York')
        .format('YYYY-MM-DD')}).pdf`,
      pdfData,
    )
    setIsLoading(false)
  }

  return (
    <div className={`HUD1${isHud1a ? 'A' : 'Page2'}-container relative`} ref={containerRef}>
      <LayoutLoading show={action !== '' || isLoading} />
      <Prompt
        when={changed}
        message={`You've made some changes on HUD1 Page2!\nAre you sure want to leave without Saving?`}
      />
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold flex items-center mb-3">
          HUD1{isHud1a ? 'A' : ' Page2'}
          {isLoading && (
            <span className="ml-3">
              <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
            </span>
          )}
        </h2>
        <p className={`hover:underline cursor-pointer flex justify-end`}>
          <span className="p-1 hover-shadow1 cursor-pointer rounded" onClick={onDownload}>
            <PrinterIcon className="w-5 h-5 text-shade-blue" />
          </span>
        </p>
      </div>
      {errorClosingCosts.length > 0 && !ignoreEror && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-[15.5px]"
          role="alert"
        >
          <div className="font-semibold flex items-center gap-1">
            One or more errors were found.{' '}
            <Button link onClick={() => setIsError(true)} color="blue" className="!m-0 !p-0">
              Click here
            </Button>{' '}
            to correct the errors.
          </div>
        </div>
      )}
      <div className="my-4 overflow-auto">
        <table className="text-[14px] w-full">
          <tbody>
            {pageType == 'hud1Page2' && (
              <HUD1_700
                inputs={inputs}
                data={data}
                renderSectionHeader={renderSectionHeader}
                renderHUD1Input={renderHUD1Input}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                showInputValue={showInputValue}
                showHistory={showHistory}
                handleContextMenu={handleContextMenu}
                notes={notes}
              />
            )}
            <HUD1_800
              inputs={inputs}
              data={data}
              ignoreEror={ignoreEror}
              isNewFeeLoading={isNewFeeLoading}
              renderSectionHeader={renderSectionHeader}
              renderHUD1Input={renderHUD1Input}
              calculateValue={calculateValue}
              setIsNewFeeLoading={setIsNewFeeLoading}
              setIsAddCCModalOpen={setIsAddCCModalOpen}
              isHud1a={isHud1a}
              onFeeDelete={onFeeDelete}
            />
            <HUD1_900
              inputs={inputs}
              data={data}
              renderSectionHeader={renderSectionHeader}
              renderHUD1Input={renderHUD1Input}
              onChange={onChange}
              onCopyClick={onCopyClick}
              calculateValue={calculateValue}
              showHistory={showHistory}
              setShowMIPFundingModal={setShowMIPFundingModal}
              setShowInterimInterestModal={setShowInterimInterestModal}
              setOVData={setOVData}
              handleContextMenu={handleContextMenu}
              notes={notes}
            />
            <HUD1_1000
              inputs={inputs}
              data={data}
              renderSectionHeader={renderSectionHeader}
              renderHUD1Input={renderHUD1Input}
              calculateValue={calculateValue}
              onUpdateMonthReserve={onUpdateMonthReserve}
              isUpdateMonthReserve={isUpdateMonthReserve}
            ></HUD1_1000>
            <HUD1_1100
              inputs={inputs}
              data={data}
              ignoreEror={ignoreEror}
              isNewFeeLoading={isNewFeeLoading}
              renderSectionHeader={renderSectionHeader}
              renderHUD1Input={renderHUD1Input}
              calculateValue={calculateValue}
              setIsNewFeeLoading={setIsNewFeeLoading}
              setIsAddCCModalOpen={setIsAddCCModalOpen}
              isHud1a={isHud1a}
              onFeeDelete={onFeeDelete}
            />
            <HUD1_1200
              inputs={inputs}
              data={data}
              ignoreEror={ignoreEror}
              isNewFeeLoading={isNewFeeLoading}
              renderSectionHeader={renderSectionHeader}
              renderHUD1Input={renderHUD1Input}
              calculateValue={calculateValue}
              setIsNewFeeLoading={setIsNewFeeLoading}
              setIsAddCCModalOpen={setIsAddCCModalOpen}
              isHud1a={isHud1a}
              onFeeDelete={onFeeDelete}
            />
            <HUD1_1300
              inputs={inputs}
              data={data}
              ignoreEror={ignoreEror}
              isNewFeeLoading={isNewFeeLoading}
              renderSectionHeader={renderSectionHeader}
              renderHUD1Input={renderHUD1Input}
              calculateValue={calculateValue}
              setIsNewFeeLoading={setIsNewFeeLoading}
              setIsAddCCModalOpen={setIsAddCCModalOpen}
              isHud1a={isHud1a}
              onFeeDelete={onFeeDelete}
            />
            {!isHud1a && (
              <tr>
                <td className="bg-blue-100 p-2">1400. TOTAL SETTLEMENT CHARGES</td>
                {inputs['totalSettlementCharges']?.map((item: any) => {
                  return <td className="bg-blue-100 p-2">{renderHUD1Input(item)}</td>
                })}
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isHud1a && (
        <>
          <div className="grid gap-6 md:grid-cols-4 grid-cols-1 text-[14px]">
            {renderSectionHeader('1500. Disbursements To', 'plain')}
            {inputs['disbursementsTo']?.map((input: any) => {
              return <div className="md:col-span-2 col-span-1">{renderHUD1Input(input, input.key)}</div>
            })}
          </div>
          <div className="grid gap-6 md:grid-cols-4 grid-cols-1 text-[14px] border-double border-gray-300 border-t-2 mt-4 py-4 ">
            <div className="md:col-span-2 col-span-1">
              {inputs['disbursementsTotal'] &&
                renderHUD1Input(inputs['disbursementsTotal'][0], inputs['disbursementsTotal'][0].key)}
            </div>
          </div>
          <div className="grid gap-6 md:grid-cols-4 grid-cols-1 text-[14px]">
            {renderSectionHeader('1600. Net Settlement:', 'plain')}
            {inputs['netSettlement']?.map((input: any) => {
              return <div className="md:col-span-2 col-span-1">{renderHUD1Input(input, input.key)}</div>
            })}
          </div>
        </>
      )}
      <div className="flex justify-center mt-4">
        <Button disabled={!changed} onClick={onSaveChanges} loading={action === 'saveChanges'}>
          Save Changes
        </Button>
      </div>
      {Object.keys(ovData).length > 0 && (
        <OverrideCaclModal
          key={ovData.overrideKey}
          title={ovData.title}
          calcValue={ovData.calcValue}
          ovValue={ovData.ovValue}
          ovOptions={ovData.ovOptions}
          onClose={closeOVModal}
        />
      )}

      {Object.keys(closingCostsDetailData).length > 0 && (
        <ClosingCostsDetailsModal
          closingCosts={closingCostsDetailData}
          loanNumber={loanNumber}
          loanAmount={data['HUD1.Line202Calc']}
          onClose={closeCostingDetailModal}
          onSubmit={closeCostingDetailModal}
          onDelete={onDeleteFee}
        />
      )}

      {Object.keys(prepaidItemsDetailData).length > 0 && (
        <PrepaidItemsDetailsModal
          prepaidItems={prepaidItemsDetailData}
          onClose={closeCostingDetailModal}
          onSubmit={closeCostingDetailModal}
        />
      )}

      {isAddCCModalOpen && <AddNewFeeModal onClose={closeAddNewFeeModal}></AddNewFeeModal>}

      {isError && (
        <ErrorClosingCostsModal
          errorClosingCosts={errorClosingCosts}
          onClose={() => setIsError(false)}
          onErrorSelect={onErrorModalClose}
          onIngoreError={() => setIgnoreError(true)}
        />
      )}
      {showMIPFundingModal && <MIPFundingFeeModal data={data} onClose={closeMIPFundingModal} />}
      {showInterimInterestModal && <InterimInterestModal data={data} onClose={closeMIPFundingModal} />}

      {contextMenu.visible && (
        <CustomContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          onClose={setEditField}
          valueKey={contextMenu.key}
          value={contextMenu.value}
          showHistory={showHistory}
        />
      )}

      {editField.key && (
        <FieldNote
          valueKey={editField.key}
          currentValue={editField.value!}
          noteData={notes[editField.key]}
          onClose={() => setEditField({ key: '', value: null })}
          onOk={onFieldNoteModalSubmit}
        />
      )}

      <SaveChanges show={changed} label="Save Changes" onSave={onSaveChanges} />
    </div>
  )
}
